import i18n from 'i18next';
import qs from 'qs';
import { initReactI18next } from 'react-i18next';

import ar from './translations/ar.json';
import en from './translations/en.json';

export const SupportedLanguage = {
    english: 'en',
    arabic: 'ar',
};

const defaultLanguage = SupportedLanguage.english;

const userLang = navigator.language;

const launchLanguage = () => {
    const referredLanguage = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })['lang'];

    if (referredLanguage === undefined) {
        const storedLanguage = localStorage?.getItem('@preferredLanguage');

        if (storedLanguage === null) {
            const browsersLanguage = userLang.split('-')[0];
            if (Object.values(SupportedLanguage).includes(browsersLanguage))
                return browsersLanguage;
            return defaultLanguage;
        }
        return storedLanguage;
    }

    return referredLanguage as string;
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            ar: {
                translation: ar,
            },
            en: {
                translation: en,
            },
        },
        lng: launchLanguage(),
        fallbackLng: defaultLanguage,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
