import { color } from '@jouri/components';
import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${color('secondary.200')};
    margin-top: 50px;

    footer {
        margin: 0;
        padding: 0;
        width: 100%;
    }
`;
