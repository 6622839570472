import { AlertKey } from 'constants/alert';
import { RoutePath } from 'constants/routes';
import { useDebtSummary } from 'lib/queries/user';
import { useAlertBanner } from 'providers/AlertBanner';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatSAR } from 'utils/localizationUtil';

const useCheckOverdueAndAlert = (enabled = false) => {
    const { t } = useTranslation();
    const { pushAlert, clearAlert, hasAlert } = useAlertBanner();
    const { data: debtSummary } = useDebtSummary(enabled);
    const navigate = useNavigate();

    const handlePayButtonClick = () => {
        navigate(RoutePath.PAYMENT_PLAN);
    };

    useEffect(() => {
        if (hasAlert(AlertKey.OVERDUE)) {
            clearAlert(AlertKey.OVERDUE);
        }

        if (!debtSummary?.overdueDebt) {
            return;
        }

        pushAlert({
            key: AlertKey.OVERDUE,
            message: (
                <Trans
                    i18nKey="dashboard_overdueRestrictionInfo_text"
                    values={[formatSAR(debtSummary.overdueDebt)]}
                    components={{
                        amount: <strong style={{ fontWeight: 'bold' }} />,
                    }}
                />
            ),
            type: 'error',
            persist: true,
            actionText: t('dashboard_pay_button'),
            onAction: handlePayButtonClick,
        });
    }, [t, debtSummary?.overdueDebt]);
};

export default useCheckOverdueAndAlert;
