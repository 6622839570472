export const SOCIAL_MEDIA_LINKS = [
    {
        translationKey: 'footer_contact_socials_facebook',
        icon: 'assets/icons/social/facebook.svg',
    },
    {
        translationKey: 'footer_contact_socials_twitter',
        icon: 'assets/icons/social/twitter.svg',
    },
    {
        translationKey: 'footer_contact_socials_tiktok',
        icon: 'assets/icons/social/tiktok.svg',
    },
    {
        translationKey: 'footer_contact_socials_instagram',
        icon: 'assets/icons/social/instagram.svg',
    },
];

export enum ModalDocumentType {
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    FAQ = 'FAQ',
    SHARIYAH_COMPLIANCE = 'SHARIYAH_COMPLIANCE',
    HOW_IT_WORKS = 'HOW_IT_WORKS',
    ABOUT_US = 'ABOUT_US',
}
