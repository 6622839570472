import {
    ProgressAwareFullPageLoading,
    ProgressSwitch,
} from '@jouri/components';
import { appRoutes } from 'constants/routes';
import useRoutePathTitle from 'hooks/useRoutePathTitle';
import { useOAuth } from 'providers/OAuthProvider';
import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Route, Routes } from 'react-router-dom';

export interface RouteDefinition {
    exact?: boolean;
    path: string;
    component?: any;
    layout?: any;
    guardCondition?: boolean;
    before?: () => Promise<any>;
    redirect?: string;
    titleLocKey?: string;
    title?: string;
}

interface RouteComposerProps {
    before?: () => Promise<any>;
    route: Pick<
        RouteDefinition,
        'path' | 'component' | 'layout' | 'redirect' | 'guardCondition'
    >;
}

const RouteComposer = ({ route, before, ...restProps }: RouteComposerProps) => {
    const Component = route.component || React.Fragment;
    const Layout = route.layout || React.Fragment;
    const { isLoading, isAuthenticated, login } = useOAuth();
    const [initValue, setInitValue] = useState<any>();
    const [isBeforeExecuted, setIsBeforeExecuted] = useState(false);
    const canAccessPage = useMemo(
        () => !(route.guardCondition ?? true) || isAuthenticated,
        [route.guardCondition, isAuthenticated]
    );
    const readyWithBefore = useMemo(
        () => !before || isBeforeExecuted,
        [before, isBeforeExecuted]
    );

    const beforeHandler = useCallback(async () => {
        if (before) {
            setInitValue(await before());
            setIsBeforeExecuted(true);
        }
    }, [before]);

    useEffect(() => {
        if (!isLoading && !canAccessPage) {
            login();
        }
    }, [canAccessPage, isLoading]);

    useEffect(() => {
        beforeHandler();
    }, [beforeHandler]);

    useEffect(() => {
        return () => {
            setIsBeforeExecuted(false);
        };
    }, [route.path]);

    return (
        <ProgressSwitch isReady={canAccessPage && readyWithBefore}>
            <Layout>
                <Component {...restProps} initValue={initValue} />
            </Layout>
        </ProgressSwitch>
    );
};

function AppRoutes() {
    useRoutePathTitle();

    return (
        <Suspense fallback={<ProgressAwareFullPageLoading />}>
            <Routes>
                {appRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        index={route?.exact}
                        element={
                            <RouteComposer
                                before={route?.before}
                                route={{
                                    path: route.path,
                                    component: route.component,
                                    layout: route.layout,
                                    redirect: route.redirect,
                                    guardCondition: route.guardCondition,
                                }}
                            />
                        }
                    />
                ))}
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;
