import ErrorBox from '@jouri/components/dist/components/error-box';
import styled from 'styled-components';

export const StyledErrorBox = styled(ErrorBox)`
    align-items: center;
    border-radius: 4px;
    padding: 15px 16px !important;

    .ant-btn {
        height: auto;
        padding: 0;
    }
`;
