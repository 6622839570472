import { smallScreen } from '@jouri/components';
import styled from 'styled-components';

export const SiteLayout = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 9;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .app-btn-primary-link {
        ${smallScreen`
            font-size: 10px;
            font-weight: 500;
            margin: 0;
        `}
    }
`;

export const CenterContent = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;
