import { Button, DropdownSelect, InformationBox } from '@jouri/components';
import Modal from '@jouri/components/dist/components/modal';
import InputNumber from '@jouri/components/dist/components/number-input';
import {
    QararEmployerTypes,
    QararMaritalStatuses,
} from 'constants/qararFields';
import usePersonalInfoFormik from 'hooks/usePersonalInfoFormik';
import {
    useQararEmployerTypeTranslation,
    useQararMaritalStatusTranslation,
} from 'hooks/useQararFieldTranslations';
import { useTranslation } from 'react-i18next';
import * as S from './index.styles';
import { PersonalInfoModalProps } from './types';

const PersonalInfoModal = (props: PersonalInfoModalProps) => {
    const { title, fields, visible, mandatory, loading, onSubmit, onClose } =
        props;
    const { t } = useTranslation();

    const getQararMaritalStatusTranslation = useQararMaritalStatusTranslation();
    const getQararEmployerTypeTranslation = useQararEmployerTypeTranslation();

    const maritalStatusOptions = Object.values(QararMaritalStatuses).map(
        (status) => ({
            label: getQararMaritalStatusTranslation(status),
            value: status,
        })
    );
    const employerTypeOptions = Object.values(QararEmployerTypes).map(
        (type) => ({
            label: getQararEmployerTypeTranslation(type),
            value: type,
        })
    );

    const formik = usePersonalInfoFormik({
        fields,
        onSubmit(values) {
            onSubmit?.(values);
        },
    });

    const renderField = (label: string, field: React.ReactNode) => (
        <S.Label>
            <S.LabelText>{label}</S.LabelText>
            {field}
        </S.Label>
    );

    return (
        <Modal
            visible={visible}
            title={title}
            footer={null}
            onClose={onClose}
            onCancel={onClose}
            closable={!mandatory}
            centered
        >
            <S.Content>
                <InformationBox
                    message={t('checkout_missingInfo_text')}
                    showIcon
                />
                <S.Form onSubmit={formik.handleSubmit}>
                    {renderField(
                        t('signUp_maritalStatus_label'),
                        <DropdownSelect
                            placeholder={t('general_select_placeholder')}
                            value={formik.values.maritalStatus}
                            onChange={formik.handleChange('maritalStatus')}
                            options={maritalStatusOptions}
                            disabled={loading}
                        />
                    )}
                    {renderField(
                        t('signUp_employerType_label'),
                        <DropdownSelect
                            placeholder={t('general_select_placeholder')}
                            value={formik.values.employerType}
                            onChange={formik.handleChange('employerType')}
                            options={employerTypeOptions}
                            disabled={loading}
                        />
                    )}
                    {renderField(
                        t('signUp_declaredIncome_label'),
                        <InputNumber
                            prefix="SAR "
                            value={formik.values.declaredIncome?.toString()}
                            onChange={(value) =>
                                formik.setFieldValue('declaredIncome', value)
                            }
                            placeholder="SAR"
                            disabled={loading}
                        />
                    )}
                    <S.ButtonWrapper>
                        <Button
                            loading={loading}
                            disabled={
                                !formik.dirty || !formik.isValid || loading
                            }
                            htmlType="submit"
                        >
                            {t('general_save_label')}
                        </Button>
                    </S.ButtonWrapper>
                </S.Form>
            </S.Content>
        </Modal>
    );
};

export default PersonalInfoModal;
