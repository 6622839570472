import { color } from '@jouri/components';
import styled from 'styled-components';

export const OuterContainer = styled.div`
    background-color: ${color('base.white')};
    padding: 34px;
    box-sizing: border-box;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    row-gap: 64px;
    color: ${color('neutral.500')};
`;

export const UpperSectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 22px;

    .mobile & {
        flex-direction: column;
    }
`;

export const UpperPrimarySection = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    .mobile & ul {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
`;

export const UpperSecondarySection = styled.div`
    margin-top: 68px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
`;

export const LowerSectionContainer = styled.div`
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    line-height: 16px;
`;

export const LowerPrimarySection = styled.div`
    font-weight: 400;

    & > ul {
        column-gap: 24px;
        display: flex;
        flex-direction: row;
        list-style: none;
    }

    .mobile & {
        & > ul {
            flex-direction: column;
            row-gap: 8px;
        }
    }
`;

export const LowerSecondarySection = styled.div`
    font-weight: 500;

    & > ul {
        column-gap: 16px;
        display: flex;
        flex-direction: row;
        list-style: none;
    }

    .mobile & {
        & > ul {
            flex-direction: column;
            row-gap: 8px;
        }
    }
`;

export const BrandSlogan = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
`;

export const LogoContainer = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SloganText = styled.div`
    font-size: 16px;
    font-weight: 400;
`;

export const Social = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    justify-content: flex-end;

    .mobile & {
        justify-content: center;
    }
`;

export const SocialMediaLink = styled.a`
    width: 32px;
    height: 32px;
    display: block;
    outline: none;
    text-decoration: none;
    color: inherit;
    border-radius: 50%;
`;

export const SocialMediaIcon = styled.img`
    width: 32px;
    height: 32px;
`;

export const Address = styled.address`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    text-align: right;

    .mobile & {
        text-align: left;
    }
`;

export const Title = styled.span`
    font-size: inherit;
    line-height: inherit;
    font-weight: 700;
`;

export const Link = styled.a<{ bold?: boolean }>`
    font-size: inherit;
    line-height: inherit;
    font-weight: ${({ bold }) => (bold ? '700' : 'inherit')};
    text-decoration: none;
`;

export const LinkBold = styled(Link)`
    font-weight: 700;
`;
