import SpriteSVG from '@jouri/components/dist/components/svg-icon/SpriteSVG';
import i18n from 'assets/i18n';
import moment from 'moment';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeGTM } from './utils/gtm';

void initializeGTM();
i18n.init();

if (i18n.language === 'ar') {
    require('moment/locale/ar');
}

moment.locale(i18n.language);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <>
        <App />
        <SpriteSVG />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
