import PersonalInfoCheck from 'components/PersonalInfoCheck';
import useCheckOverdueAndAlert from 'hooks/useCheckOverdueAndAlert';
import useInitializeConsoleDataroid from 'hooks/useInitializeConsoleDataroid';
import useInitializeHttpService from 'hooks/useInitializeHttpService';
import { PropsWithChildren, useMemo } from 'react';
import { AppInitializerContext } from './context';
import UserAgreementCheck from 'components/UserAgreementCheck';

const AppInitializer = ({ children }: PropsWithChildren<{}>) => {
    const isHttpServiceReady = useInitializeHttpService();
    const isReady = useMemo(() => isHttpServiceReady, [isHttpServiceReady]);

    useCheckOverdueAndAlert(isHttpServiceReady);
    useInitializeConsoleDataroid(isHttpServiceReady);

    return (
        <AppInitializerContext.Provider value={{ isHttpServiceReady, isReady }}>
            {isReady && <PersonalInfoCheck />}
            {isReady && <UserAgreementCheck />}
            {children}
        </AppInitializerContext.Provider>
    );
};

export default AppInitializer;
