const userLang = navigator.language;

const currencyFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'SAR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const formatSAR = (amount?: number | null): string =>
    currencyFormatter.format(amount ?? 0);

export const dateTimeFormatter = Intl.DateTimeFormat(userLang, {
    dateStyle: 'full',
    timeStyle: 'medium',
});
