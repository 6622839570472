import { Button, SvgIcon, useWindowSize } from '@jouri/components';
import Logo from '@jouri/components/dist/components/logo';
import { Col, Row } from 'antd';
import * as BL from 'components/layouts/BaseLayout/BaseLayout.styles';
import { RoutePath } from 'constants/routes';
import { useOAuth } from 'providers/OAuthProvider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { NavLink } from 'react-router-dom';
import * as S from './Header.styles';

interface LinkProps {
    to: string;
    label: string;
}

const Link = ({ to, label }: LinkProps) => {
    return (
        <NavLink
            to={to}
            className={({ isActive }) =>
                `nav-link ${isActive ? 'nav-link-active' : ''}`
            }
        >
            <S.LinkText>{label}</S.LinkText>
        </NavLink>
    );
};

const Header = () => {
    const { logout, isAuthenticated } = useOAuth();
    const { t } = useTranslation();
    const { isDesktopScreen } = useWindowSize();
    const [isMenuShown, setIsMenuShown] = useState(isDesktopScreen);

    const menuItems = [
        {
            label: t('general_header_dashboard_button'),
            to: RoutePath.ROOT,
        },
        {
            label: t('general_header_purchases_button'),
            to: RoutePath.PURCHASES,
        },
        {
            label: t('general_header_paymentPlan_button'),
            to: RoutePath.PAYMENT_PLAN,
        },
        {
            label: t('general_header_campaigns_button'),
            to: RoutePath.CAMPAIGNS,
        },
    ];

    const toggleMenu = useCallback(
        (isShown: boolean) => {
            if (isDesktopScreen) return;
            setIsMenuShown(isShown);
        },
        [isDesktopScreen]
    );

    useEffect(() => {
        setIsMenuShown(isDesktopScreen);
    }, [isDesktopScreen]);

    return (
        <S.Container
            className={!isDesktopScreen ? 'mobile' : ''}
            data-cy="header-container"
        >
            <BL.CenterContent>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Row align="middle">
                            {isAuthenticated && (
                                <Col style={{ position: 'relative' }}>
                                    <S.HamburgerMenuButton
                                        data-ci={'hamburger-icon-button'}
                                        onClick={() => toggleMenu(!isMenuShown)}
                                    >
                                        <SvgIcon name="hamburger-menu" />
                                    </S.HamburgerMenuButton>
                                    {isMenuShown && (
                                        <S.HamburgerMenuButtonMask />
                                    )}
                                </Col>
                            )}
                            <Col>
                                <Row align="middle">
                                    <NavLink to={RoutePath.ROOT}>
                                        <S.LogoContainer>
                                            <Logo type="light" size={104} />
                                        </S.LogoContainer>
                                    </NavLink>
                                </Row>
                            </Col>
                            {isAuthenticated && (
                                <Col>
                                    {isMenuShown && (
                                        <OutsideClickHandler
                                            disabled={isDesktopScreen}
                                            onOutsideClick={() =>
                                                toggleMenu(false)
                                            }
                                            useCapture={true}
                                        >
                                            <S.NavLinkContainer
                                                onClick={() =>
                                                    toggleMenu(false)
                                                }
                                            >
                                                {menuItems.map(
                                                    (item, index) => (
                                                        <Link
                                                            key={index}
                                                            {...item}
                                                        />
                                                    )
                                                )}
                                            </S.NavLinkContainer>
                                        </OutsideClickHandler>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Col>
                    {isAuthenticated && (
                        <Col>
                            <Row align="middle">
                                <NavLink to={RoutePath.PROFILE}>
                                    <S.Profile />
                                </NavLink>
                                <Button
                                    data-cy="logout-button"
                                    type="link"
                                    className="logout-btn"
                                    onClick={() => {
                                        logout();
                                    }}
                                >
                                    <SvgIcon name="logout" />
                                </Button>
                            </Row>
                        </Col>
                    )}
                </Row>
            </BL.CenterContent>
        </S.Container>
    );
};

export default Header;
