interface IAppConfig {
    cdnUrl: string;
    oauthApiUrl: string;
    oauthClientId: string;
    marketingAppUrl: string;
    consoleAppUrl: string;
    paymentGatewayEnabled: boolean;
    deviceManagerUrl: string;
    dataroidSdkUrl: string;
    dataroidSdkKey: string;
    dataroidEndpoint: string;
    dataroidAppVersion: string;
    ssoBaseUrl: string;
    authVersion: 'v1' | 'v2';
    applePayInitiativeContext: string;
    envValue: string;
}

export default (): IAppConfig => ({
    cdnUrl: process.env.REACT_APP_COMMON_CDN_URL || '',
    oauthApiUrl: process.env.REACT_APP_OAUTH_API_URL || '',
    oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || '',
    marketingAppUrl: process.env.REACT_APP_MARKETING_APP_URL || '',
    consoleAppUrl: process.env.REACT_APP_CONSOLE_APP_URL || '',
    paymentGatewayEnabled: !!parseInt(
        process.env.REACT_APP_PAYMENT_GATEWAY_ENABLED || '0'
    ),
    deviceManagerUrl: process.env.REACT_APP_DEVICE_MANAGER_URL || '',
    dataroidSdkUrl: process.env.REACT_APP_DATAROID_SDK_URL || '',
    dataroidSdkKey: process.env.REACT_APP_DATAROID_SDK_KEY || '',
    dataroidEndpoint: process.env.REACT_APP_DATAROID_ENDPOINT || '',
    dataroidAppVersion: process.env.REACT_APP_DATAROID_APP_VERSION || '',
    ssoBaseUrl: process.env.REACT_APP_SSO_BASE_URL || '',
    authVersion:
        (process.env
            .REACT_APP_MISPAY_AUTH_VERSION as IAppConfig['authVersion']) ||
        'v1',
    applePayInitiativeContext:
        process.env.REACT_APP_APPLE_PAY_INITIATIVE_CONTEXT ||
        'console.mispay.co',
    envValue: process.env.REACT_APP_ENV_VALUE || 'DEV',
});
