import { HttpResponse } from 'lib/http/http.model';
import httpService from 'lib/http/http.service';
import config from 'config';
import DeviceApiEndpoint from './device.endpoint';

const DeviceApi = {
    init: (): HttpResponse<string> =>
        httpService.client.post(DeviceApiEndpoint.INIT, undefined, {
            baseURL: config().deviceManagerUrl,
        }),
};

type DeviceApi = typeof DeviceApi;

export default DeviceApi;
