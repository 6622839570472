import {
    ThemeOption,
    ThemeProvider as BaseThemeProvider,
} from '@jouri/components';
import { PropsWithChildren } from 'react';
import GlobalStyle from 'theme/GlobalStyle';

const ThemeProvider = ({ children }: PropsWithChildren<{}>) => (
    <BaseThemeProvider defaultTheme={ThemeOption.Light}>
        <GlobalStyle />
        {children}
    </BaseThemeProvider>
);

export default ThemeProvider;
