const UserApiEndpoint = {
    GET_USER_DETAILS: '/user',
    UPDATE_PERSONAL_INFO: '/user/personal-info',
    UPDATE_USER_AGREEMENT_INFO: '/user/agreement',
    UPDATE_USER_PROFILE: '/refresh-citizenship',
    GET_BUDGET: '/budget',
    GET_DEBT_INFO: '/debt-summary',
    GET_PURCHASES: '/transaction',
    GET_TRANSACTION_DETAIL: '/transaction/:transactionId',
    GET_CAMPAIGNS: '/campaigns',
    UPDATE_PHONE_NUMBER: '/user/phone-number',
    VERIFY_PHONE_NUMBER: '/user/verify-phone-number',
    RESEND_OTP_CODE: '/user/phone-number/resend',
    UPDATE_EMAIL: '/user/email',
    DEACTIVATE_ACCOUNT: '/deactivate',
    SAVED_CARDS: '/user/saved-cards',
    CHECK_AGREEMENT: '/user/agreement',
    SET_AS_DEFAULT_CARD: '/user/saved-cards/:id/default',
    DELETE_CARD: '/user/saved-cards/:id',
};

export default UserApiEndpoint;
