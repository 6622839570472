import {
    QararEmployerTypes,
    QararMaritalStatuses,
} from 'constants/qararFields';
import { useTranslation } from 'react-i18next';

export const useQararMaritalStatusTranslation = () => {
    const { t } = useTranslation();

    const translation = {
        [QararMaritalStatuses.SINGLE]: t('signUp_single_text'),
        [QararMaritalStatuses.MARRIED]: t('signUp_married_text'),
        [QararMaritalStatuses.DIVORCED]: t('signUp_divorced_text'),
        [QararMaritalStatuses.WIDOWED]: t('signUp_widow_text'),
    };

    return (status: QararMaritalStatuses) => translation[status];
};

export const useQararEmployerTypeTranslation = () => {
    const { t } = useTranslation();

    const translation = {
        [QararEmployerTypes.GOVERNMENT]: t('signUp_government_text'),
        [QararEmployerTypes.SEMI_GOVERNMENT]: t('signUp_semiGovernment_text'),
        [QararEmployerTypes.PRIVATE]: t('signUp_private_text'),
        [QararEmployerTypes.RETIRED]: t('signUp_retired_text'),
        [QararEmployerTypes.OTHER]: t('signUp_other_text'),
        [QararEmployerTypes.SELF_EMPLOYED]: t('signUp_selfEmployed_text'),
    };

    return (type: QararEmployerTypes) => translation[type];
};
