import { ErrorType } from '@jouri/components/dist/components/error-screen';
import { RouteDefinition } from 'components/AppRoutes';
import BaseLayout from 'components/layouts/BaseLayout';
import { lazy } from 'react';

const ErrorPage = lazy(() => import('pages/ErrorPage'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const CampaignsPage = lazy(() => import('pages/CampaignsPage'));
const Purchases = lazy(() => import('pages/Purchases'));
const PaymentPlanPage = lazy(() => import('pages/PaymentPlanPage'));
const PaymentInformationPage = lazy(() => import('pages/PaymentInformation'));
const ProfilePage = lazy(() => import('pages/Profile'));
const PaymentResultPage = lazy(() => import('pages/PaymentResultPage'));

export const RoutePath = {
    ROOT: '/',
    DASHBOARD: '/dashboard',
    PURCHASES: '/purchases',
    PAYMENT_PLAN: '/payment-plan',
    PAYMENT_INFORMATION: '/payment-information',
    CAMPAIGNS: '/campaigns',
    PROFILE: '/profile',
    ERROR: '/error',
    PAYMENT_SUCCESS: '/payment-success',
    PAYMENT_FAILURE: '/payment-failure',
    LOCATION_UNAVAILABLE: '/location-unavailable',
};

export const appRoutes: RouteDefinition[] = [
    {
        path: '*',
        component: ErrorPage,
        before: () => Promise.resolve({ type: ErrorType.ERR_404 }),
        titleLocKey: 'console_docTitle_error',
    },
    {
        exact: true,
        path: RoutePath.LOCATION_UNAVAILABLE,
        component: ErrorPage,
        before: () => Promise.resolve({ type: ErrorType.E0103 }),
        titleLocKey: 'console_docTitle_error',
    },
    {
        exact: true,
        path: RoutePath.ROOT,
        layout: BaseLayout,
        component: Dashboard,
        titleLocKey: 'console_docTitle_dashboard',
    },
    {
        exact: true,
        path: RoutePath.CAMPAIGNS,
        layout: BaseLayout,
        component: CampaignsPage,
        titleLocKey: 'console_docTitle_campaigns',
    },
    {
        exact: true,
        path: RoutePath.DASHBOARD,
        layout: BaseLayout,
        component: Dashboard,
        titleLocKey: 'console_docTitle_dashboard',
    },
    {
        exact: true,
        path: RoutePath.PURCHASES,
        layout: BaseLayout,
        component: Purchases,
        titleLocKey: 'console_docTitle_purchases',
    },
    {
        exact: true,
        path: RoutePath.PAYMENT_PLAN,
        layout: BaseLayout,
        component: PaymentPlanPage,
        titleLocKey: 'console_docTitle_paymentPlans',
    },
    {
        exact: true,
        path: RoutePath.PAYMENT_INFORMATION,
        layout: BaseLayout,
        component: PaymentInformationPage,
        titleLocKey: 'console_docTitle_paymentInformation',
    },
    {
        exact: true,
        path: RoutePath.PROFILE,
        layout: BaseLayout,
        component: ProfilePage,
        titleLocKey: 'console_docTitle_profile',
    },
    {
        exact: true,
        path: RoutePath.ERROR,
        component: ErrorPage,
        titleLocKey: 'console_docTitle_error',
    },
    {
        exact: true,
        path: RoutePath.PAYMENT_SUCCESS,
        component: PaymentResultPage,
        layout: BaseLayout,
        guardCondition: false,
        before: () => Promise.resolve({ type: 'success' }),
    },
    {
        exact: true,
        path: RoutePath.PAYMENT_FAILURE,
        component: PaymentResultPage,
        layout: BaseLayout,
        guardCondition: false,
        before: () => Promise.resolve({ type: 'failure' }),
    },
];
