import Modal from '@jouri/components/dist/components/modal';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
    height: 80vh;
    margin-top: 20px;
    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
`;

export const ModalScrollContent = styled.div`
    overflow: scroll;
    max-height: 60vh;
    overflow-y: auto;
`;

export const ButtonWrapper = styled.div`
    display: flex;
`;
