import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './index.styles';
import { useCheckUserAgreement } from 'lib/queries/user';
import { useUpdateUserAgreementVersion } from 'lib/mutations/user';
import useGetErrorText from 'hooks/useGetErrorText';
import { Button, useToastMessage } from '@jouri/components';

interface UserAgreementCheckProps {
    onComplete?: () => void;
}

const UserAgreementCheck = ({ onComplete }: UserAgreementCheckProps) => {
    const { t, i18n } = useTranslation();
    const { send } = useToastMessage();
    const [settled, setSettled] = useState(false);
    const { data: agreementDetails, refetch: fetchAgreementDetails } =
        useCheckUserAgreement(i18n.language, false);
    const {
        mutate: updateAgreementVersion,
        isLoading: isUpdateAgreementVersionLoading,
    } = useUpdateUserAgreementVersion();
    const shouldShowModal = !settled && agreementDetails?.approved === false;

    const getErrorText = useGetErrorText();

    const handleFormSubmit = () => {
        if (agreementDetails?.document?.version) {
            updateAgreementVersion(
                { agreementVersion: agreementDetails?.document.version },
                {
                    onSuccess: () => {
                        setSettled(true);
                    },
                    onError: (error) => {
                        send(getErrorText(error), 'error');
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (agreementDetails && agreementDetails?.approved) {
            setSettled(true);
        }
    }, [agreementDetails?.approved]);

    useEffect(() => {
        if (settled) {
            onComplete?.();
        }
    }, [settled]);

    useEffect(() => {
        fetchAgreementDetails();
    }, []);

    return (
        <S.ModalWrapper
            visible={shouldShowModal}
            title={t('signUp_serviceConfirmation_termsAndConditions_label')}
            footer={null}
            closable={false}
            centered
        >
            <S.Content>
                <S.ModalScrollContent>
                    {agreementDetails?.document?.content && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: agreementDetails.document.content,
                            }}
                        ></div>
                    )}
                </S.ModalScrollContent>
                <Button
                    loading={isUpdateAgreementVersionLoading}
                    disabled={isUpdateAgreementVersionLoading}
                    onClick={handleFormSubmit}
                >
                    {t('general_accept_label')}
                </Button>
            </S.Content>
        </S.ModalWrapper>
    );
};

export default UserAgreementCheck;
