import { AppEnv } from '@jouri/components';
import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponse,
} from 'axios';
import {
    decryptWithAES,
    decryptWithRSA,
    encryptWithAES,
    encryptWithRSA,
    generateKey,
} from 'utils/secure.util';
import i18n from 'assets/i18n';
import config from 'config';
import * as uuid from 'uuid';

const axiosConfig: AxiosRequestConfig = {
    withCredentials: true,
    baseURL: `/api/console`,
};

const axiosCDNConfig: AxiosRequestConfig = {
    baseURL: config().cdnUrl,
    withCredentials: true,
};

class HttpService {
    private _client: AxiosInstance;
    private _mock: AxiosInstance;
    private _cdn: AxiosInstance;
    private authToken?: string;
    private _location?: [number, number] | undefined;
    private secureList: PartialRecord<string, any> = {};
    public deviceId?: string;
    public errorHandler?: (response: AxiosResponse) => void;

    constructor() {
        this._client = axios.create(axiosConfig);
        this._cdn = axios.create(axiosCDNConfig);
        this._mock = axios.create(axiosConfig);
        this.applyInterceptor();
    }

    public setAuthToken(token?: string) {
        this.authToken = token;
    }

    public getAuthToken(): string {
        return this.authToken || '';
    }

    private applyInterceptor() {
        const securePayload = async (options: {
            url?: string;
            method?: string;
            httpAgent?: string;
        }) => {
            const httpCallId = uuid.v4();

            const payload = {
                path: options.url,
                method: options.method,
                id: httpCallId,
                agent: options.httpAgent,
                origin: window.origin,
            };

            const aesKey = generateKey();

            const encryptedPayload = encryptWithAES(
                JSON.stringify(payload),
                aesKey
            ) as unknown as string;

            const handshake = await axios.get(
                `/handshake?_=${new Date().getTime()}`,
                {
                    headers: {
                        ['x-secure-key']: aesKey,
                        ['x-secure-payload']: encryptedPayload,
                    },
                    baseURL: '/api/secure',
                }
            );

            const secure = JSON.parse(
                decryptWithAES(handshake.data.payload, aesKey)
            );

            const requestPayload = encryptWithRSA(
                'abcde', // post, put, body
                secure.publicKey
            );

            if (AppEnv.isTest || AppEnv.isDev) {
                console.log(requestPayload);
            }

            this.secureList[httpCallId] = secure;

            return {
                'x-secure-id': httpCallId,
                'Cache-Control': 'no-cache',
            };
        };

        this._client.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
                const appendToHeader = (newHeaders: AxiosRequestHeaders) =>
                    (config.headers = {
                        ...(config.headers ?? {}),
                        ...newHeaders,
                    });

                appendToHeader({
                    'accept-language': i18n.language?.replace(/\//g, ''),
                });

                const isSecure = () =>
                    config.headers && config.headers['x-secure'];

                appendToHeader({
                    'x-app-location': this.location.join(','),
                });

                if (this.deviceId) {
                    appendToHeader({ 'x-device-id': this.deviceId });
                }

                if (this.getAuthToken()) {
                    appendToHeader({
                        authorization: `Bearer ${this.getAuthToken()}`,
                    });

                    if (isSecure()) {
                        const securityHeaders = await securePayload({
                            url: config.url,
                            httpAgent: config.httpAgent,
                            method: config.method,
                        });

                        appendToHeader(securityHeaders);
                    }
                }

                return config;
            }
        );

        this._client.interceptors.response.use(
            async (response: AxiosResponse) => {
                const accessToken = response.headers['x-access-token'];

                if (accessToken) {
                    this.setAuthToken(accessToken);
                }

                const secureId =
                    response.headers['x-secure'] &&
                    response.headers['x-secure-id'];

                if (secureId) {
                    const secure = this.secureList[secureId];
                    delete this.secureList[secureId];
                    const data = response && response.data;
                    const payload = decryptWithRSA(
                        data.result,
                        secure.privateKey
                    );

                    if (AppEnv.isTest || AppEnv.isDev) {
                        console.log('decrypteWithRSA', {
                            url: response.config.url,
                            payload: JSON.parse(payload),
                        });
                    }
                    return JSON.parse(payload);
                } else {
                    return response && response.data;
                }
            },
            (error: any) => {
                if (this.errorHandler) {
                    this.errorHandler(error.response as AxiosResponse);
                }
                return Promise.reject(error.response);
            }
        );
        this._mock.interceptors.request.use((config) => {
            console.log('REQUEST', config.url, config.headers, config.data);
            return config;
        });
        this._mock.interceptors.response.use((response: AxiosResponse) => {
            console.log(
                'RESPONSE',
                response.config.url,

                response.data
            );
            return response && response.data;
        });

        this._cdn.interceptors.request.use((config: AxiosRequestConfig) => {
            return {
                ...config,
                headers: {
                    ...config.headers,
                },
            };
        });
        this._cdn.interceptors.response.use(
            (response: AxiosResponse) => {
                return response && response.data;
            },
            (error: any) => {
                if (!['post', 'patch', 'put'].includes(error.config.method)) {
                    console.log(error);
                }
                return Promise.reject(error);
            }
        );
    }

    public get client(): AxiosInstance {
        return this._client;
    }

    public get mock(): AxiosInstance {
        return this._mock;
    }

    public get cdn(): AxiosInstance {
        return this._cdn;
    }

    public get location(): [number, number] {
        return this._location ?? [-1, -1];
    }

    public set location(value: [number, number] | undefined) {
        this._location = value;
    }
}

const httpService = new HttpService();

export default httpService;
