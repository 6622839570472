import { DocumentType } from '@jouri/components';
import GenericFooter from '@jouri/components/dist/components/footer';
import * as BL from 'components/layouts/BaseLayout/BaseLayout.styles';
import config from 'config';
import { useTranslation } from 'react-i18next';
import * as S from './Footer.styles';

const Footer = () => {
    const { t, i18n } = useTranslation();

    return (
        <S.FooterContainer>
            <BL.Content>
                <GenericFooter
                    copyrightText={t('general_copyright_text')}
                    language={i18n.language as 'ar' | 'en'}
                    links={[
                        {
                            label: t(
                                'signUp_serviceConfirmation_termsAndConditions_label'
                            ),
                            documentType: DocumentType.termsAndConditions,
                        },
                        {
                            label: t(
                                'signUp_serviceConfirmation_privacyPolicy_label'
                            ),
                            documentType: DocumentType.privacyPolicy,
                        },
                        {
                            label: t('general_faq_button'),
                            onClick: () => {
                                window.location.href = `${
                                    config().marketingAppUrl
                                }/questions`;
                            },
                        },
                        {
                            label: t('general_aboutUs_button'),
                            onClick: () => {
                                window.location.href = `${
                                    config().marketingAppUrl
                                }/about-us`;
                            },
                        },
                    ]}
                />
            </BL.Content>
        </S.FooterContainer>
    );
};

export default Footer;
