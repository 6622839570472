import { getOAuth } from '@jouri/components';
import i18n from 'assets/i18n';
import config from 'config';
import { PropsWithChildren } from 'react';

const { OAuthProvider: BaseOAuthProvider, useOAuth } = getOAuth(
    config().authVersion
);

const OAuthProvider = ({ children }: PropsWithChildren<unknown>) => (
    <BaseOAuthProvider
        marketingUrl={config().marketingAppUrl}
        lang={i18n.language}
        baseUrl={config().oauthApiUrl}
        clientId={config().oauthClientId}
        baseSSOUrl={config().ssoBaseUrl}
    >
        {children}
    </BaseOAuthProvider>
);

export { useOAuth };

export default OAuthProvider;
