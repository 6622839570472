import {
    AntAppThemeStyle,
    color,
    FontFaceStyle,
    ThemeStyledProps,
} from '@jouri/components';
import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle<ThemeStyledProps>`
    /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      color: inherit;
      vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
      display: block;
    }

    /* * {
      transition: all 0.2s;
    } */
    *, *:before, *:after {
      -webkit-box-sizing: border-box; 
      -moz-box-sizing: border-box; 
      box-sizing: border-box;
    }
    ol, ul {
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    html {
      font-family: 'MISPayFontFamily';
    }

    body {
      font-size: 16px;
      background-color: ${color('neutral.50')}
    }

    #root {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
  
    .app-svg-sprite {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
      }

    .nav-link {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .nav-link-active {
      p {
        -webkit-text-stroke: 0.8px white !important;

        &::before {
          width: 24px;
        }
      }
    }

    .ant-modal-mask {
      background: rgba(0, 0, 0, 0.85);
    }

    .app-icon {
      display: flex;
    }
`;

const GlobalStyle = () => (
    <>
        <AntAppThemeStyle />
        <FontFaceStyle />
        <Styles />
    </>
);

export default GlobalStyle;
