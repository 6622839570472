import { color, lessThanDesktop, Text } from '@jouri/components';
import styled from 'styled-components';

export const Label = styled.label`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const LabelText = styled(Text).attrs({ type: 'Body2.Regular' })`
    color: ${color('neutral.300')};
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 24px;

    ${lessThanDesktop`
        width: 100%;
        flex:1;
        justify-content: space-between;
        margin-bottom: 20px;
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
