import * as CryptoJS from 'crypto-js';
import * as forge from 'node-forge';
import * as uuid from 'uuid';

export function generateKey() {
    return CryptoJS.SHA256(`${uuid.v4()}|${new Date()}`).toString();
}

function chunkString(str: string, length: number): string[] {
    return str.match(new RegExp('.{1,' + length + '}', 'g')) as string[];
}

export const encryptWithAES = (data: string, passphrase: string): string => {
    return CryptoJS.AES.encrypt(data, passphrase).toString();
};

export const decryptWithAES = (data: string, passphrase: string): string => {
    return CryptoJS.AES.decrypt(data, passphrase).toString(CryptoJS.enc.Utf8);
};

export function encryptWithRSA(content: string, publicKeyPem: string) {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const strArr = chunkString(content, 200);
    const encryptedArr: string[] = [];
    strArr.forEach((e) => {
        encryptedArr.push(
            forge.util.bytesToHex(publicKey.encrypt(forge.util.encodeUtf8(e)))
        );
    });
    return encryptedArr.join('.');
}

export function decryptWithRSA(content: string, privateKeyPem: string) {
    const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
    const payloads = content.split('.');
    let load = '';
    payloads.forEach((data) => {
        const resolver = privateKey.decrypt(forge.util.hexToBytes(data));
        load += resolver;
    });
    return load;
}
