import { Paginated } from '@jouri/components';
import { HttpResponse } from 'lib/http/http.model';
import httpService from 'lib/http/http.service';
import UserApiEndpoint from './user.endpoint';
import {
    AddNewCardPaymentResponse,
    CheckAgreementResponse,
    GetBudgetResponse,
    GetCampaignsRes,
    GetDebtSummaryResponse,
    GetPurchasesPayload,
    GetTransactionDetailResponse,
    Purchase,
    ResendOTPCodePayload,
    SaveUserDefaultCardReq,
    UpdateAgreementVersion,
    UpdateEmailPayload,
    UpdatePersonalInfoPayload,
    UpdatePhoneNumberPayload,
    UpdatePhoneNumberResponse,
    UserDetailResponse,
    UserSavedCard,
    VerifyPhoneNumberPayload,
} from './user.model';

const UserApi = {
    getUserDetails: (): HttpResponse<UserDetailResponse> =>
        httpService.client.get(UserApiEndpoint.GET_USER_DETAILS, {
            headers: {
                'x-secure': true,
            },
        }),
    updatePersonalInfo: (
        payload: UpdatePersonalInfoPayload
    ): HttpResponse<{}> =>
        httpService.client.put(UserApiEndpoint.UPDATE_PERSONAL_INFO, payload),
    updateAgreementVersion: (
        payload: UpdateAgreementVersion
    ): HttpResponse<{}> =>
        httpService.client.put(
            UserApiEndpoint.UPDATE_USER_AGREEMENT_INFO,
            payload
        ),
    updateUserProfile: (): HttpResponse<UserDetailResponse> =>
        httpService.client.put(UserApiEndpoint.UPDATE_USER_PROFILE),
    getBudget: (): HttpResponse<GetBudgetResponse> =>
        httpService.client.get(UserApiEndpoint.GET_BUDGET, {
            headers: {
                'x-secure': true,
            },
        }),
    getDebtSummary: (): HttpResponse<GetDebtSummaryResponse> =>
        httpService.client.get(UserApiEndpoint.GET_DEBT_INFO, {
            headers: {
                'x-secure': true,
            },
        }),
    getPurchases: (
        payload: GetPurchasesPayload
    ): HttpResponse<Paginated<Purchase>> =>
        httpService.client.get(UserApiEndpoint.GET_PURCHASES, {
            params: payload,
        }),
    getTransactionDetail: (
        transactionId: string
    ): HttpResponse<GetTransactionDetailResponse> =>
        httpService.client.get(
            `${UserApiEndpoint.GET_TRANSACTION_DETAIL}/${transactionId}`
        ),
    getCampaigns: (): HttpResponse<GetCampaignsRes> =>
        httpService.mock.get(UserApiEndpoint.GET_CAMPAIGNS),
    updateEmail: (payload: UpdateEmailPayload): HttpResponse<unknown> =>
        httpService.client.put(UserApiEndpoint.UPDATE_EMAIL, payload),
    updatePhoneNumber: (
        payload: UpdatePhoneNumberPayload
    ): HttpResponse<UpdatePhoneNumberResponse> =>
        httpService.client.post(UserApiEndpoint.UPDATE_PHONE_NUMBER, payload),
    verifyPhoneNumber: (
        payload: VerifyPhoneNumberPayload
    ): HttpResponse<unknown> =>
        httpService.client.put(UserApiEndpoint.VERIFY_PHONE_NUMBER, payload),
    resendOTPCode: (
        payload: ResendOTPCodePayload
    ): HttpResponse<UpdatePhoneNumberResponse> =>
        httpService.client.post(UserApiEndpoint.RESEND_OTP_CODE, payload),
    deactivateAccount: (): HttpResponse<unknown> =>
        httpService.client.post(UserApiEndpoint.DEACTIVATE_ACCOUNT),
    getSavedCards: (): HttpResponse<UserSavedCard[]> =>
        httpService.client.get(UserApiEndpoint.SAVED_CARDS),
    checkAgreement: (lang: string): HttpResponse<CheckAgreementResponse> =>
        httpService.client.get(
            `${UserApiEndpoint.CHECK_AGREEMENT}?lang=${lang}`
        ),
    createNewCardAdditionPaymentUrl:
        (): HttpResponse<AddNewCardPaymentResponse> =>
            httpService.client.post(UserApiEndpoint.SAVED_CARDS),
    setUserDefaultCard: (
        payload: SaveUserDefaultCardReq
    ): HttpResponse<unknown> =>
        httpService.client.put(
            UserApiEndpoint.SET_AS_DEFAULT_CARD.replace(':id', payload.cardId)
        ),
    deleteUserSavedCard: (
        payload: SaveUserDefaultCardReq
    ): HttpResponse<unknown> =>
        httpService.client.delete(
            UserApiEndpoint.DELETE_CARD.replace(':id', payload.cardId)
        ),
};

type UserApi = typeof UserApi;

export default UserApi;
