import { useDocHead } from '@jouri/components';
import { appRoutes } from 'constants/routes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const DEFAULT_DOC_TITLE_LOC_KEY = 'console_docTitle_default';

export default function useRoutePathTitle() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { setTitle } = useDocHead();

    useEffect(() => {
        const { titleLocKey = DEFAULT_DOC_TITLE_LOC_KEY } =
            appRoutes.find((route) => route.path === pathname) || {};

        setTitle(t(titleLocKey));
    }, [pathname]);
}
