import { useWindowSize } from '@jouri/components';
import Logo from '@jouri/components/dist/components/logo';
import { Col, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentModal from './components/DocumentModal';
import { ModalDocumentType, SOCIAL_MEDIA_LINKS } from './constants';
import * as S from './PostLoginFooter.styles';

interface LinkGroup {
    heading: string;
    links: Array<{ href?: string; onClick?: () => unknown; label: string }>;
}

const PostLoginFooter: FC = () => {
    const { t } = useTranslation();
    const { isMobileScreen } = useWindowSize();
    const [modalDocumentType, setModalDocumentType] =
        useState<ModalDocumentType | null>(null);

    const handlePrivacyPolicyClick = () => {
        setModalDocumentType(ModalDocumentType.PRIVACY_POLICY);
    };

    const handleTermsAndConditionsClick = () => {
        setModalDocumentType(ModalDocumentType.TERMS_AND_CONDITIONS);
    };

    const handleContentModalClose = () => {
        setModalDocumentType(null);
    };

    const primarySectionLinkGroups: LinkGroup[] = [
        {
            heading: t('footer_links_mis_pay'),
            links: [
                {
                    label: t('footer_links_about_us'),
                    onClick: () =>
                        setModalDocumentType(ModalDocumentType.ABOUT_US),
                },
                {
                    label: t('footer_links_shariya_compliance'),
                    onClick: () =>
                        setModalDocumentType(
                            ModalDocumentType.SHARIYAH_COMPLIANCE
                        ),
                },
            ],
        },
        {
            heading: t('footer_links_questions'),
            links: [
                {
                    label: t('footer_links_faq'),
                    onClick: () => setModalDocumentType(ModalDocumentType.FAQ),
                },
                {
                    label: t('footer_links_how_it_works'),
                    onClick: () =>
                        setModalDocumentType(ModalDocumentType.HOW_IT_WORKS),
                },
            ],
        },
    ];

    return (
        <>
            <S.OuterContainer className={isMobileScreen ? 'mobile' : ''}>
                <S.Container>
                    <S.UpperSectionContainer>
                        <S.UpperPrimarySection>
                            <S.BrandSlogan>
                                <S.LogoContainer>
                                    <Logo type="dark" size={110} />
                                </S.LogoContainer>
                                <S.SloganText>
                                    {t('footer_punch_line')}
                                </S.SloganText>
                            </S.BrandSlogan>
                            <Row>
                                <Space size={48} align="start">
                                    {primarySectionLinkGroups.map(
                                        (linkGroup, groupIndex) => (
                                            <Col key={groupIndex}>
                                                <Space
                                                    size={16}
                                                    direction="vertical"
                                                >
                                                    <S.Title>
                                                        {linkGroup.heading}
                                                    </S.Title>
                                                    <ul>
                                                        {linkGroup.links.map(
                                                            (link, index) => (
                                                                <li key={index}>
                                                                    <S.Link
                                                                        href={
                                                                            link.href
                                                                        }
                                                                        onClick={
                                                                            link.onClick
                                                                        }
                                                                    >
                                                                        {
                                                                            link.label
                                                                        }
                                                                    </S.Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </Space>
                                            </Col>
                                        )
                                    )}
                                </Space>
                            </Row>
                        </S.UpperPrimarySection>
                        <S.UpperSecondarySection>
                            <S.Social>
                                {SOCIAL_MEDIA_LINKS.map((sm) => (
                                    <S.SocialMediaLink
                                        href={t(sm.translationKey)}
                                        key={sm.translationKey}
                                        target="_blank"
                                    >
                                        <S.SocialMediaIcon src={sm.icon} />
                                    </S.SocialMediaLink>
                                ))}
                            </S.Social>
                            <S.Address>
                                <S.Title>{t('footer_contact_header')}</S.Title>
                                <S.Link
                                    href={`mailto:${t('footer_contact_email')}`}
                                >
                                    {t('footer_contact_email')}
                                </S.Link>
                                <S.Link
                                    href={`tel:${t('footer_contact_phone')}`}
                                    bold
                                >
                                    {t('footer_contact_phone')}
                                </S.Link>
                                <span>
                                    {t('footer_contact_address_first')}
                                    <br />
                                    {t('footer_contact_address_second')}
                                </span>
                            </S.Address>
                        </S.UpperSecondarySection>
                    </S.UpperSectionContainer>
                    <S.LowerSectionContainer>
                        <S.LowerPrimarySection>
                            <ul>
                                <li>{t('footer_license_year')}</li>
                                <li>{t('footer_license_rights')}</li>
                            </ul>
                        </S.LowerPrimarySection>
                        <S.LowerSecondarySection>
                            <ul>
                                <li>
                                    <S.Link onClick={handlePrivacyPolicyClick}>
                                        {t('footer_law_privacy')}
                                    </S.Link>
                                </li>
                                <li>
                                    <S.Link
                                        onClick={handleTermsAndConditionsClick}
                                    >
                                        {t('footer_law_terms')}
                                    </S.Link>
                                </li>
                            </ul>
                        </S.LowerSecondarySection>
                    </S.LowerSectionContainer>
                </S.Container>
            </S.OuterContainer>
            <DocumentModal
                documentType={modalDocumentType}
                onClose={handleContentModalClose}
            />
        </>
    );
};

export default PostLoginFooter;
