import { color, smallScreen } from '@jouri/components';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 2px solid ${color('base.white')};
    border-radius: 50%;
    overflow: hidden;

    ${smallScreen`
          width: 24px;
          height: 24px;
    `}
`;

export const ProfileImage = styled.img.attrs({ alt: 'profile image' })`
    width: 100%;
    height: 100%;
`;
