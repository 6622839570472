import { DataroidConfig } from '@jouri/components';
import config from 'config';
import { t } from 'i18next';

export const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
};

export const SEARCH_PARAMS = {
    JOURI_CODE: 'jouriCode',
};

export const DATE_FORMATS = {
    LONG_MONTH_AND_YEAR: 'MMMM YYYY',
    FULL_DATE_AS_NUMBER: 'DD-MM-YYYY',
    YEAR_MONTH_DAY_AS_NUMBER: 'YYYY-MM-DD',
    DAY_MONTH_YEAR_WITH_LEADING_ZERO: 'DD/MM/YYYY',
    ONLY_LONG_MONTH: 'MMMM',
    ONLY_YEAR: 'YYYY',
    LONG_DATE_WITH_TIME: 'DD/MM/YYYY HH:mm',
};

export const TIMER_DURATION_AS_SECONDS = 120;

export const SA_PHONE_COUNTRY_CODE = '+966';

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export const INITIAL_PAGINATION = {
    page: 1,
    size: 10,
};

export const DATAROID_CONFIG: DataroidConfig = {
    dataroidAppName: t('dataroid_app_name'),
    dataroidAppVersion: t('dataroid_app_version'),
    dataroidSdkKey: config().dataroidSdkKey,
    dataroidEndpoint: config().dataroidEndpoint,
    dataroidSdkUrl: config().dataroidSdkUrl,
};
