import { useToastMessage } from '@jouri/components';
import { ErrorType } from '@jouri/components/dist/components/error-screen';
import { AxiosResponse } from 'axios';
import { StyledErrorBox } from 'components/ErrorBox/ErrorBox.style';
import { RoutePath } from 'constants/routes';
import useGetErrorText from 'hooks/useGetErrorText';
import { useOAuth } from 'providers/OAuthProvider';
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { pickErrorCode } from 'utils/errorUtil';
import { ErrorHandlerContext } from './context';
import { NullableErrorType } from './types';

const checkFraudError = (errorType: ErrorType) =>
    [
        ErrorType.E0075,
        ErrorType.E0076,
        ErrorType.E0077,
        ErrorType.E0078,
    ].includes(errorType);

const ErrorHandlerProvider = ({ children }: PropsWithChildren<{}>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { send } = useToastMessage();
    const { logout } = useOAuth();
    const [errorScreenCode, setErrorScreenCode] =
        useState<NullableErrorType>(null);
    const [errorBoxMessage, setErrorBoxMessage] = useState('');
    const isFraudError = useMemo(
        () => errorScreenCode != null && checkFraudError(errorScreenCode),
        [errorScreenCode]
    );
    const getErrorText = useGetErrorText();

    const handleError = useCallback(
        (error: any, errorBox: boolean = true) => {
            if (checkFraudError(pickErrorCode(error)) || !errorBox) {
                setErrorScreenCode(pickErrorCode(error));
                return;
            }

            const errorText = getErrorText(error);

            if (errorText) {
                setErrorBoxMessage(errorText);
            }
        },
        [t]
    );

    const cleanErrorBoxMessage = useCallback(() => {
        setErrorBoxMessage('');
    }, []);

    const renderErrorBoxMessage = useCallback(() => {
        if (!errorBoxMessage) {
            return null;
        }

        return (
            <StyledErrorBox
                message={errorBoxMessage}
                closeText={t('general_close_button')}
                onClose={cleanErrorBoxMessage}
            />
        );
    }, [errorBoxMessage, t, cleanErrorBoxMessage]);

    const axiosErrorHandler = useCallback((response: AxiosResponse) => {
        if (response.status === 401) {
            send(t('error_E0003_errorMessage'), 'error');
            logout();
        } else if (
            [ErrorType.ERR_404, ErrorType.ERR_500].includes(response.status)
        ) {
            setErrorScreenCode(response.status);
        } else if (checkFraudError(response.data.result.message)) {
            setErrorScreenCode(response.data.result.message);
        } else if (errorScreenCode) {
            setErrorScreenCode(null);
        }
    }, []);

    useEffect(() => {
        if (errorScreenCode) {
            navigate(RoutePath.ERROR, { replace: true });
        }
    }, [errorScreenCode, navigate]);

    return (
        <ErrorHandlerContext.Provider
            value={{
                errorScreenCode,
                setErrorScreenCode,
                isFraudError,
                axiosErrorHandler,
                cleanErrorBoxMessage,
                errorBoxMessage,
                handleError,
                setErrorBoxMessage,
                renderErrorBoxMessage,
            }}
        >
            {children}
        </ErrorHandlerContext.Provider>
    );
};

export default ErrorHandlerProvider;
