import { Footer, PostLoginFooter } from 'components/Footer';
import Header from 'components/Header';
import { AlertBanner } from 'providers/AlertBanner';
import { useOAuth } from 'providers/OAuthProvider';
import { FC, ReactNode } from 'react';
import * as S from './BaseLayout.styles';

interface BaseLayoutProps {
    children: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const { isAuthenticated } = useOAuth();

    return (
        <S.Container>
            <S.HeaderContainer>
                <Header />
                <AlertBanner />
            </S.HeaderContainer>
            <S.SiteLayout>
                <S.Content>{children}</S.Content>
            </S.SiteLayout>
            {isAuthenticated ? <PostLoginFooter /> : <Footer />}
        </S.Container>
    );
};

export default BaseLayout;
