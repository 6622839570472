import { IFrameModal } from '@jouri/components';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalDocumentType } from '../../constants';

interface DocumentModalProps {
    documentType: ModalDocumentType | null;
    onClose: () => void;
}

interface DocumentDefinition {
    title: string;
    contentSource: string;
}

const MARKETING_APP_URL = process.env.REACT_APP_MARKETING_APP_URL;

const DocumentModal: FC<DocumentModalProps> = (props) => {
    const { documentType, onClose } = props;
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const definitions: Record<ModalDocumentType, DocumentDefinition> = useMemo(
        () => ({
            [ModalDocumentType.PRIVACY_POLICY]: {
                title: t('footer_modal_privacy_title'),
                contentSource: `${MARKETING_APP_URL}/privacy-policy?noBaseLayout=true&lang=${lang}`,
            },
            [ModalDocumentType.TERMS_AND_CONDITIONS]: {
                title: t('footer_modal_terms_title'),
                contentSource: `${MARKETING_APP_URL}/terms-and-conditions?noBaseLayout=true&lang=${lang}`,
            },
            [ModalDocumentType.FAQ]: {
                title: t('footer_modal_faq_title'),
                contentSource: `${MARKETING_APP_URL}/questions?noBaseLayout=true&lang=${lang}`,
            },
            [ModalDocumentType.HOW_IT_WORKS]: {
                title: t('footer_modal_how_it_works_title'),
                contentSource: `${MARKETING_APP_URL}/how-it-works?noBaseLayout=true&lang=${lang}`,
            },
            [ModalDocumentType.ABOUT_US]: {
                title: t('footer_modal_about_us_title'),
                contentSource: `${MARKETING_APP_URL}/about-us?noBaseLayout=true&lang=${lang}`,
            },
            [ModalDocumentType.SHARIYAH_COMPLIANCE]: {
                title: t('footer_modal_shariya_compliance_title'),
                contentSource: `${MARKETING_APP_URL}/shariya-compliance?noBaseLayout=true&lang=${lang}`,
            },
        }),
        [t, lang]
    );
    const currDocumentDefinition = useMemo(
        () => (documentType ? definitions[documentType] : null),
        [definitions, documentType]
    );

    if (!currDocumentDefinition) return null;

    return (
        <IFrameModal
            title={currDocumentDefinition.title}
            contentDefinition={currDocumentDefinition}
            onClose={onClose}
            width="100%"
            style={{ maxWidth: '720px', padding: '0 16px' }}
            contentStyle={{
                maxHeight: '700px',
            }}
        />
    );
};

export default DocumentModal;
