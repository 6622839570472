import { useTranslation } from 'react-i18next';
import { getErrorLocKey } from 'utils/errorUtil';

const useGetErrorText = () => {
    const { t } = useTranslation();

    return (error: any) => {
        const code =
            typeof error === 'string' ? error : error?.data?.result?.message;

        const translated = t(getErrorLocKey(code));

        if (!code || isRawErrorCode(translated)) {
            return t('general_service_errorMessage');
        }

        return translated;
    };
};

const isRawErrorCode = (errorCode: string): boolean =>
    /error_E\d{4}_errorMessage/.test(errorCode);

export default useGetErrorText;
