import { RoutePath } from 'constants/routes';
import useDeviceId from 'lib/hooks/useDeviceId';
import httpService from 'lib/http/http.service';
import { useErrorHandler } from 'providers/ErrorHandler';
import { useOAuth } from 'providers/OAuthProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useHandleLocationSharing from './useHandleLocationSharing';

export default function useInitializeHttpService() {
    const { axiosErrorHandler, handleError } = useErrorHandler();
    const { token: oAuthToken, isLoading: isOAuthLoading, login } = useOAuth();
    const navigate = useNavigate();
    const { requestDeviceId, deviceId } = useDeviceId({ onError: handleError });

    const ready = useMemo(
        () => !!oAuthToken && !!deviceId && !!axiosErrorHandler,
        [oAuthToken, deviceId, axiosErrorHandler]
    );

    const handleLocationSharingSuccess = useCallback(
        (lat: number, long: number) => {
            httpService.location = [lat, long];
            requestDeviceId();
        },
        [requestDeviceId]
    );

    const handleLocationSharingFailure = useCallback(() => {
        navigate(RoutePath.LOCATION_UNAVAILABLE);
    }, [navigate]);

    useHandleLocationSharing(
        handleLocationSharingSuccess,
        handleLocationSharingFailure
    );

    useEffect(() => {
        httpService.errorHandler = axiosErrorHandler;
    }, [axiosErrorHandler]);

    useEffect(() => {
        if (deviceId) {
            httpService.deviceId = deviceId;
        }
    }, [deviceId]);

    useEffect(() => {
        if (isOAuthLoading) {
            return;
        }

        if (oAuthToken) {
            httpService.setAuthToken(oAuthToken);
        } else {
            login();
        }
    }, [oAuthToken, isOAuthLoading, login]);

    return ready;
}
