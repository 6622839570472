import DeviceApi from 'lib/api/device/device.api';
import { useEffect, useRef, useState } from 'react';

export interface UseDeviceIdOptions {
    requestOnMount?: boolean;
    onError?: (error: any) => void;
}

export default function useDeviceId(options: UseDeviceIdOptions = {}) {
    const isRequested = useRef(false);
    const [deviceId, setDeviceId] = useState<string | null>(null);

    const requestDeviceId = async () => {
        if (isRequested.current) return;

        isRequested.current = true;

        try {
            const { result: deviceIdResult } = await DeviceApi.init();

            setDeviceId(deviceIdResult);
        } catch (error: any) {
            options.onError?.(error);
        }
    };

    useEffect(() => {
        if (options.requestOnMount) {
            requestDeviceId();
        }
    }, [options.requestOnMount]);

    return {
        requestDeviceId,
        deviceId,
    };
}
