import { color } from '@jouri/components';
import Text from '@jouri/components/dist/components/typography';
import ProfileButton from 'components/ProfileButton';
import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: ${color('primary.700')};

    &.mobile {
        padding: 0 24px;
    }

    .logout-btn {
        padding: 0;
        .app-icon {
            color: ${color('base.white')} !important;
        }
    }
`;

export const LinkText = styled(Text)`
    position: relative;
    color: ${color('base.white')};
    font-weight: 400;
    font-size: 14px;

    &::before {
        content: '';
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 0px;
        height: 4px;
        background-color: ${color('primary.200')};
        transition: all 0.1s;
    }

    &:hover::before {
        width: 24px;
    }

    &:hover {
        // changing font weight shifts ui
        -webkit-text-stroke: 0.8px ${color('base.white')};
    }
`;

export const NavLinkContainer = styled.nav`
    display: flex;
    justify-items: center;
    right: 70px;
    gap: 40px;
    align-items: center;
    background-color: ${color('primary.700')};

    .mobile & {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        z-index: 9999;
        flex-direction: column;
        gap: 0;

        .nav-link {
            width: 100%;
            padding: 12px 24px;

            &:last-child {
                padding-bottom: 24px;
            }
        }
    }
`;

export const LogoContainer = styled.div`
    margin-right: 32px;
    max-height: 60px;
    display: flex;
    align-items: center;
`;

export const Profile = styled(ProfileButton)`
    margin-right: 24px;

    .mobile & {
        margin-right: 10px;
    }
`;

export const HamburgerMenuButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    margin-right: 18px;
    display: none;

    .mobile & {
        display: block;
    }
`;

export const HamburgerMenuButtonMask = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;
