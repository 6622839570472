import { color } from '@jouri/components';
import styled from 'styled-components';
import { useAlertBanner } from './context';

const AlertBanner = () => {
    const { alerts } = useAlertBanner();

    return (
        <AlertsWrapper>
            {alerts.map((alert) => (
                <AlertContainer
                    key={alert.key}
                    className={alert.type}
                    role="alert"
                >
                    <AlertInnerContainer>
                        {alert.Component ? (
                            <alert.Component onAction={alert.onAction} />
                        ) : (
                            <>
                                <AlertTextWrapper>
                                    {typeof alert.message === 'string' ? (
                                        <AlertText>{alert.message}</AlertText>
                                    ) : (
                                        alert.message
                                    )}
                                </AlertTextWrapper>
                                <AlertButton onClick={alert.onAction}>
                                    {alert.actionText}
                                </AlertButton>
                            </>
                        )}
                    </AlertInnerContainer>
                </AlertContainer>
            ))}
        </AlertsWrapper>
    );
};

const AlertsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const AlertContainer = styled.div`
    &.error {
        --background-color: ${color('error.100')};
        --border-color: ${color('error.400')};
        --text-color: ${color('neutral.600')};
        --button-color: ${color('neutral.600')};
        --button-text-color: ${color('base.white')};
    }

    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
`;

const AlertInnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    column-gap: 24px;
`;

const AlertTextWrapper = styled.div`
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
`;

const AlertText = styled.p`
    margin: 0;
`;

const AlertButton = styled.button`
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: none;
    padding: 8px 16px;
    min-width: 100px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
`;

export default AlertBanner;
