import { useGeolocation } from '@jouri/components';
import { useEffect } from 'react';

const TIMEOUT_MS = 10000;

export default function useHandleLocationSharing(
    onSuccess?: (lat: number, long: number) => void,
    onFailure?: () => void
) {
    const { position, isGranted, isSettled, isTimedOut } = useGeolocation({
        requestOnMount: true,
        live: false,
        timeout: TIMEOUT_MS,
    });

    useEffect(() => {
        if (isSettled && !isGranted) {
            onFailure?.();
        }
    }, [isSettled, isGranted, onFailure]);

    useEffect(() => {
        if (position) {
            const { latitude, longitude } = position.coords;

            onSuccess?.(latitude, longitude);
        }
    }, [position, onSuccess]);

    useEffect(() => {
        if (isTimedOut) {
            onFailure?.();
        }
    }, [isTimedOut, onFailure]);

    return isSettled;
}
