import UserApi from 'lib/api/user/user.api';
import { useQuery } from 'react-query';

export const UserQueryKeys = {
    debtSummary: ['user-debt-summary'],
    userDetails: ['user-details'],
    savedCards: ['user-saved-cards'],
    checkUserAgreementIsApproved: ['check-user-agreement-is-approved'],
};

export const useDebtSummary = (enabled = true) =>
    useQuery({
        queryKey: UserQueryKeys.debtSummary,
        queryFn: UserApi.getDebtSummary,
        select: (data) => data.result,
        enabled,
    });

export const useUserDetails = () =>
    useQuery({
        queryKey: UserQueryKeys.userDetails,
        queryFn: UserApi.getUserDetails,
        select: (data) => data.result,
        enabled: false,
    });

export const useSavedCards = () =>
    useQuery({
        queryKey: UserQueryKeys.savedCards,
        queryFn: UserApi.getSavedCards,
        select: (data) => data.result,
        refetchOnWindowFocus: false,
    });

export const useCheckUserAgreement = (lang: string, enabled = true) =>
    useQuery({
        queryKey: UserQueryKeys.checkUserAgreementIsApproved,
        queryFn: () => UserApi.checkAgreement(lang),
        select: (data) => data?.result,
        refetchOnWindowFocus: false,
        enabled,
    });
