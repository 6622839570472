import {
    nest,
    ProgressProvider,
    withDocHead,
    withRTLSupport,
} from '@jouri/components';
import AppRoutes from 'components/AppRoutes';
import {
    AlertBannerProvider,
    AppInitializer,
    ErrorHandlerProvider,
    OAuthProvider,
    QueryClientProvider,
    ThemeProvider,
} from 'providers';
import { BrowserRouter } from 'react-router-dom';

const AppProviders = nest(
    [BrowserRouter, { basename: '/' }],
    QueryClientProvider,
    ThemeProvider,
    ProgressProvider,
    OAuthProvider,
    ErrorHandlerProvider,
    AlertBannerProvider,
    AppInitializer
);

const App = () => (
    <AppProviders>
        <AppRoutes />
    </AppProviders>
);

export default withRTLSupport(withDocHead(App));
