import UserApi from 'lib/api/user/user.api';
import { useMutation } from 'react-query';

export const useUpdatePersonalInfo = () =>
    useMutation(UserApi.updatePersonalInfo);

export const useUpdateUserAgreementVersion = () =>
    useMutation(UserApi.updateAgreementVersion);

export const useCreateNewCardAdditionPaymentUrl = () =>
    useMutation(UserApi.createNewCardAdditionPaymentUrl);

export const useSetUserDefaultCard = () => {
    return useMutation(UserApi.setUserDefaultCard);
};

export const useDeleteUserSavedCard = () => {
    return useMutation(UserApi.deleteUserSavedCard);
};
