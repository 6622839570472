import { PropsWithChildren } from 'react';
import {
    QueryClient,
    QueryClientProvider as BaseQueryClientProvider,
} from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 3000, // 3 seconds
        },
    },
});

const QueryClientProvider = ({ children }: PropsWithChildren<{}>) => (
    <BaseQueryClientProvider client={queryClient}>
        {children}
    </BaseQueryClientProvider>
);

export default QueryClientProvider;
