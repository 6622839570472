import { useToastMessage } from '@jouri/components';
import PersonalInfoModal from 'components/PersonalInfoModal';
import useGetErrorText from 'hooks/useGetErrorText';
import { useUpdatePersonalInfo } from 'lib/mutations/user';
import { useUserDetails } from 'lib/queries/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalInfoFields } from 'types/personalInfo';

interface PersonalInfoCheckProps {
    onComplete?: () => void;
}

const PersonalInfoCheck = ({ onComplete }: PersonalInfoCheckProps) => {
    const { t } = useTranslation();
    const { send } = useToastMessage();
    const [settled, setSettled] = useState(false);
    const { data: userDetails, refetch: fetchUserDetails } = useUserDetails();
    const {
        mutate: updatePersonalInfo,
        isLoading: isUpdatePersonalInfoLoading,
    } = useUpdatePersonalInfo();
    const shouldShowModal = !settled && userDetails?.hasMissingInfo;
    const fields: Partial<PersonalInfoFields> = {
        declaredIncome: userDetails?.declaredIncome,
        employerType: userDetails?.employerType,
        maritalStatus: userDetails?.maritalStatus,
    };

    const getErrorText = useGetErrorText();

    const handleFormSubmit = (values: PersonalInfoFields) => {
        updatePersonalInfo(values, {
            onSuccess: () => {
                setSettled(true);
            },
            onError: (error) => {
                send(getErrorText(error), 'error');
            },
        });
    };

    useEffect(() => {
        if (userDetails && !userDetails.hasMissingInfo) {
            setSettled(true);
        }
    }, [userDetails?.hasMissingInfo]);

    useEffect(() => {
        if (settled) {
            onComplete?.();
        }
    }, [settled]);

    useEffect(() => {
        fetchUserDetails();
    }, []);

    return (
        <PersonalInfoModal
            visible={shouldShowModal}
            title={t('checkout_missingInfo_title')}
            fields={fields}
            loading={isUpdatePersonalInfoLoading}
            onSubmit={handleFormSubmit}
            mandatory
        />
    );
};

export default PersonalInfoCheck;
