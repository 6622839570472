import { useMemo } from 'react';
import * as S from './ProfileButton.styles';

interface ProfileButtonProps {
    className?: string;
    imgUrl?: string;
}

// remove it later
const DEFAULT_IMG_URL =
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

const ProfileButton = ({ className, imgUrl }: ProfileButtonProps) => {
    const profileImgUrl = useMemo(() => imgUrl ?? DEFAULT_IMG_URL, [imgUrl]);

    return (
        <S.Container className={className}>
            <S.ProfileImage src={profileImgUrl} />
        </S.Container>
    );
};

export default ProfileButton;
