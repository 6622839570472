import {
    QararEmployerTypes,
    QararMaritalStatuses,
} from 'constants/qararFields';
import { useFormik } from 'formik';
import { PersonalInfoFields } from 'types/personalInfo';
import * as yup from 'yup';

export interface PersonalInfoFormikOptions {
    fields: Partial<PersonalInfoFields>;
    onSubmit?: (values: PersonalInfoFields) => void;
}

const usePersonalInfoFormik = (options: PersonalInfoFormikOptions) => {
    const { fields, onSubmit } = options;

    const formSchema = yup.object({
        maritalStatus: yup
            .string()
            .oneOf(Object.values(QararMaritalStatuses))
            .default(fields.maritalStatus ?? null)
            .required(''),
        employerType: yup
            .string()
            .oneOf(Object.values(QararEmployerTypes))
            .default(fields.employerType ?? null)
            .required(''),
        declaredIncome: yup
            .number()
            .default(fields.declaredIncome ?? null)
            .min(1)
            .required(''),
    });

    const formik = useFormik({
        initialValues: formSchema.getDefaultFromShape(),
        validationSchema: formSchema,
        onSubmit: async (values) => {
            onSubmit?.({
                declaredIncome: +values.declaredIncome,
                employerType: values.employerType as QararEmployerTypes,
                maritalStatus: values.maritalStatus as QararMaritalStatuses,
            });
        },
    });

    return formik;
};

export default usePersonalInfoFormik;
