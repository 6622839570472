import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { AlertBannerContext } from './context';
import { Alert } from './types';

const AlertBannerProvider = ({ children }: PropsWithChildren<unknown>) => {
    const location = useLocation();
    const [alerts, updateAlerts] = useImmer<Alert[]>([]);
    const [hiddenAlertKeys, updateHiddenAlertKeys] = useImmer<
        Array<Alert['key']>
    >([]);
    const visibleAlerts = useMemo(
        () => alerts.filter((alert) => !hiddenAlertKeys.includes(alert.key)),
        [alerts, hiddenAlertKeys]
    );

    const pushAlert = useCallback((alert: Alert) => {
        updateAlerts((draft) => {
            draft.push(alert);
        });
    }, []);
    const getAlert = useCallback(
        (key: string) => {
            return alerts.find((alert) => alert.key === key);
        },
        [alerts]
    );
    const hasAlert = useCallback(
        (key: string) => {
            return alerts.some((alert) => alert.key === key);
        },
        [alerts]
    );
    const clearAlert = useCallback((key: string) => {
        updateAlerts((draft) => {
            const index = draft.findIndex((alert) => alert.key === key);
            if (index !== -1) {
                draft.splice(index, 1);
            }
        });
    }, []);
    const clearAllAlerts = useCallback((ignorePersists?: boolean) => {
        updateAlerts((draft) =>
            ignorePersists ? draft.filter((alert) => alert.persist) : []
        );
    }, []);
    const toggleAlert = useCallback((key: string, force?: boolean) => {
        updateHiddenAlertKeys((draft) => {
            const index = draft.findIndex((k) => k === key);

            if (index !== -1 || force) {
                draft.splice(index, 1);
            } else if (index === -1) {
                draft.push(key);
            }
        });
    }, []);

    // Clear non-persist alerts on route change
    useEffect(() => {
        clearAllAlerts(true);
    }, [location.pathname]);

    return (
        <AlertBannerContext.Provider
            value={{
                alerts: visibleAlerts,
                toggleAlert,
                clearAlert,
                clearAllAlerts,
                pushAlert,
                getAlert,
                hasAlert,
            }}
        >
            {children}
        </AlertBannerContext.Provider>
    );
};

export default AlertBannerProvider;
