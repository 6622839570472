import { useInitializeDataroid } from '@jouri/components';
import { DATAROID_CONFIG } from 'constants/common';
import { useUserDetails } from 'lib/queries/user';
import { useEffect, useRef } from 'react';
import { hashData } from 'utils/hash';

const useInitializeConsoleDataroid = (canSetUserProfile = false) => {
    const { isSDKReady: isDataroidSDKReady, sdk: dataroidSdk } =
        useInitializeDataroid(DATAROID_CONFIG);
    const isDataroidUserProfileSet = useRef(false);

    const { refetch: fetchUserDetails } = useUserDetails();

    const initializeUserProfile = async () => {
        try {
            const { data: userDetails } = await fetchUserDetails();

            if (!userDetails) {
                return;
            }

            const hashedUserId = hashData(userDetails.id);

            dataroidSdk?.setUserProfile({
                cid: hashedUserId,
            });
        } catch (error) {
            // do nothing
        }
    };

    useEffect(() => {
        if (
            canSetUserProfile &&
            isDataroidSDKReady &&
            !isDataroidUserProfileSet.current
        ) {
            isDataroidUserProfileSet.current = true;
            initializeUserProfile();
        }
    }, [canSetUserProfile, isDataroidSDKReady]);
};

export default useInitializeConsoleDataroid;
