export enum QararMaritalStatuses {
    SINGLE = 'Single',
    MARRIED = 'Married',
    DIVORCED = 'Divorced',
    WIDOWED = 'Widowed',
}

export enum QararEmployerTypes {
    GOVERNMENT = 'Government',
    SEMI_GOVERNMENT = 'Semi-Government',
    PRIVATE = 'Private',
    RETIRED = 'Retired',
    OTHER = 'Other',
    SELF_EMPLOYED = 'Self-Employed',
}
