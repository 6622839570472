export const getErrorLocKey = (errorCode: string) => {
    if (errorCode?.includes(' ')) {
        return 'general_service_errorMessage';
    }

    return `error_${errorCode}_errorMessage`;
};

export const pickErrorCode = (error: any) => {
    return typeof error === 'string' ? error : error?.data?.result?.message;
};
